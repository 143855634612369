export default {
  GetMission: state=>id =>{
    return state.objectives.find(mission => mission.id === id)
  },
  GetDoneObj: state =>{
    return state.objectives.find(ob => ob.isDone === true)
  },
  GetProgressObj: state =>{
    return state.objectives.find(ob => ob.isProgress === true)
  }
}
