const INITIAL_DATA_URL = "https://backendapinodejs.timecheckit.com/api/projects"
import axios from "axios"

var projectAction = {
  async fetchProjects({ commit }) {
    commit("SET_LOADING_STATE", true)
    return axios.get(`${INITIAL_DATA_URL}?users=${JSON.parse(localStorage.getItem("userData"))._id}`).then(res => {
      commit("SET_PROJECT_COUNT", res.data)
      commit("SET_INITIAL_DATA", res.data)
      commit("SET_LOADING_STATE", false)
    })
  },
  async fetchProject({ commit }, payload) {
    commit("SET_LOADING_STATE", true)
    return axios.get(`${INITIAL_DATA_URL}?id=${payload.boardId}`).then(res => {
      commit("SET_PROJECT", res.data)
      commit("SET_LOADING_STATE", false)
    })
  },

  async saveTaskBoard({ commit }, payload) {
    return axios.post(INITIAL_DATA_URL, payload).then(res => {
      commit("SAVE_TASKBOARD", payload);
    });
  },
  async archiveTaskBoard({ commit }, payload) {
    return axios.put(`${INITIAL_DATA_URL}/${payload.board._id}`, payload.board).then(async (res) => {
      await projectAction.fetchProjects({ commit });
    });
    //commit("ARCHIVE_TASKBOARD", payload);
  },
  async restoreTaskBoard({ commit }, payload) {
    return axios.put(`${INITIAL_DATA_URL}/${payload.board._id}`, payload.board).then(async (res) => {
      await projectAction.fetchProjects({ commit });
    });
    // commit("RESTORE_TASKBOARD", payload)
  },
  async deleteTaskBoard({ commit }, payload) {
    return axios.delete(`${INITIAL_DATA_URL}/${payload.board._id}`).then(async (res) => {
      await projectAction.fetchProjects({ commit });
    });
    // commit("RESTORE_TASKBOARD", payload)
  },


  async setActiveTaskBoard({ commit }, payload) {
    commit("SET_ACTIVE_TASKBOARD", payload)
  },
  async setInActiveTaskBoard({ commit }, payload) {
    commit("SET_iN_ACTIVE_TASKBOARD", payload)
  },


  async saveTaskList({ commit }, payload) {
    return axios.post(`https://backendapinodejs.timecheckit.com/api/projects/${payload.boardId}/lists`, { name: payload.name }).then(async (res)  => {
      await projectAction.fetchProjects({ commit }, payload);  
      commit("SAVE_TASKLIST", payload);
    });
  },
  async updateTaskList({ commit }, payload) {
    return axios.put(`https://backendapinodejs.timecheckit.com/api/projects/${payload.boardId}/lists/${payload.boardList.id}`, { name: payload.boardList.name }).then(async (res) => {
      await projectAction.fetchProjects({ commit }, payload);
    });
    //  return axios.put(`https://backendapinodejs.timecheckit.com/api/projects/${payload.boardId}/lists/${payload.boardList.id}`, { name: payload.boardList.name }).then(async (res) => {
    //   //  commit("UPDATE_TASKLIST", payload);
    //  });
  },
  async setActiveTaskList({ commit }, payload) {
    commit("SET_ACTIVE_TASKLIST", payload)
  },
  async setInActiveTaskList({ commit }, payload) {
    commit("SET_IN_ACTIVE_TASKLIST", payload)
  },
  async archiveTaskList({ commit }, payload) {
    //commit("ARCHIVE_TASKLIST", payload)
    return axios.put(`https://backendapinodejs.timecheckit.com/api/projects/${payload.boardId}/lists/${payload.listId}`, { status: "inactive" }).then(async (res) => {
      await projectAction.fetchProject({ commit }, payload);
    });
  },
  async restoreTaskList({ commit }, payload) {
    // commit("RESTORE_TASKLIST", payload)
    return axios.put(`${INITIAL_DATA_URL}/${payload.boardId}/lists/${payload.listId}`, payload.newList).then(async (res) => {
      await projectAction.fetchProjects({ commit },payload);
    });
  },

  async reorderTaskLists({ commit }, payload) {
    console.log("value", payload);

    // commit("REORDER_TASKLISTS", payload)
    return axios.put(`https://backendapinodejs.timecheckit.com/api/projects/${payload.boardId}/lists/${payload.obj1._id}`,  payload.obj1 ).then(async (res) => {
      return axios.put(`https://backendapinodejs.timecheckit.com/api/projects/${payload.boardId}/lists/${payload.obj2._id}`, payload.obj2).then(async (res) => {
        await projectAction.fetchProjects({ commit }, payload);
      });
    });
  },
  async reorderTaskListItems({ commit }, payload) {
    return axios.put(`https://backendapinodejs.timecheckit.com/api/projects/${payload.boradId}/lists/${payload.listId}/items/${payload.obj1._id}`, payload.obj1).then(async (res) => {
      return axios.put(`https://backendapinodejs.timecheckit.com/api/projects/${payload.boradId}/lists/${payload.listId}/items/${payload.obj2._id}`, payload.obj2).then(async (res) => {
        await projectAction.fetchProjects({ commit }, payload);
      });
    });
  },
  async reorderTaskListToListItems({ commit }, payload) {
    console.log("action",payload)

    return axios.put(`https://backendapinodejs.timecheckit.com/api/projects/${payload.boradId}/lists/items/${payload.obj3}`,
      {
        fromList: payload.fromList,
        toList: payload.toList,
        toIndex: payload.toIndex,
        fromIndex: payload.fromIndex
      }).then(async (res) => {
        await projectAction.fetchProjects({ commit }, payload);
      });
  },
  
  async saveTaskListItem({ commit }, payload) {
    console.log(payload);
    return axios.post(`https://backendapinodejs.timecheckit.com/api/projects/${payload.boardId}/lists/${payload.listId}/items`, {
      name: payload.item.text,
      description: 'No description yet'
    }).then(res => {
      commit("SAVE_TASKLIST_ITEM", payload)
    });
  },
  // async saveTaskListItem({ commit }, payload) {
  //   console.log(payload);
  //   return axios.post(`https://backendapinodejs.timecheckit.com/api/projects/${payload.boardId}/lists/${payload.listId}/items/${payload.itemId}`, {
  //     name: payload.item.text,
  //     description: 'No description yet'
  //   }).then(res => {
  //     commit("SAVE_TASKLIST_ITEM", payload)
  //   });
  // },
  async updateTaskListItem({ commit }, payload) {
    console.log("i am here",payload);
    return axios.put(`https://backendapinodejs.timecheckit.com/api/projects/${payload.boardId}/lists/${payload.listId}/items/${payload.itemId}`,
      payload.item).then(res => {
        console.log("res",res)
      // commit("SAVE_TASKLIST_ITEM", payload)
    });
  },
  async saveTaskListItemTodo({ commit }, payload) {
    console.log("saveTaskListItemTodo",payload)
    return axios.post(`https://backendapinodejs.timecheckit.com/api/projects/${payload.boardId}/lists/${payload.listId}/items/${payload.itemId}/todo`, {
      text: payload.todo.text,
      done: payload.todo.done
    }).then(async (res) => {
      await projectAction.fetchProjects({ commit });
    });
  },
  async UpdateTaskListItemTodo({ commit }, payload) {
    console.log("UpdateTaskListItemTodo",payload)
    return axios.put(`https://backendapinodejs.timecheckit.com/api/projects/${payload.boardId}/lists/${payload.listId}/items/${payload.itemId}/todo/${payload.todoId}`, 
      payload.todo
    ).then(async (res) => {
      await projectAction.fetchProjects({ commit });
    });
  },
  async deleteTaskListItemTodo({ commit }, payload) {
    return axios.delete(`https://backendapinodejs.timecheckit.com/api/projects/${payload.boardId}/lists/${payload.listId}/items/${payload.itemId}/todo/${payload.todoId}`
    ).then(async (res) => {
      await projectAction.fetchProjects({ commit });
    });
  },
  
  async deleteTaskListItem({ commit }, payload) {
    commit("DELETE_TASKLIST_ITEM", payload)
  }
}

export default projectAction;
