const DATA = "https://raw.githubusercontent.com/malekabbes665/jsontest/main/meetings.json"
import axios from "axios"


export default {
  async fetchmeetings({ commit }) {
    return axios.get(DATA).then(res => {
      
      commit("SET_DATA", res.data)
    })
  },
  async saveTaskBoard({ commit }, payload) {
    //commit("SAVE_TASKBOARD", payload)
  },
  async completemeetings({ commit }, payload) {
    commit("COMPLETE_MEET", payload)
  },
  async restoremeetings({ commit }, payload) {
    commit("RESTORE_MEET", payload)
  },
  async setActivemeeting({ commit }, payload) {
    commit("SET_ACTIVE_MEET", payload)
  },
}
