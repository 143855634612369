import Vue from "vue"


export default {

  SET_INITIAL_DATA(state, payload) {
    state.todos = payload.data;
  },
  SET_TODO_COUNT(state, payload) {
    state.todoscount = payload.count;
  },
  SET_TODO_DATA(state, payload) {
    state.todos = payload.data
    localStorage.setItem('todo', JSON.stringify(payload.data))
  },
}
