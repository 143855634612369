export default {
  isLoading: state => state.isLoading,
  allBoards: state => state.boards,
  getBoard: state => state.board,
  activeBoard: state => state.activeBoard,
  inActiveBoard: state => state.inActiveBoard,
  unarchivedBoards: state => state.boards.filter(b => b.status ==="active"),
  archivedBoards: state => state.boards.filter(b => b.status === "inactive"),
  archivedLists: state => (state.activeBoard ? state.activeBoard.lists.filter(l => l.status === "inactive") : []),
  unarchivedLists: state => (state.activeBoard ? state.activeBoard.lists.filter(l => l.status === "active") : [])
}
