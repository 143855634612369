
import Vue from 'vue'
import { ToastPlugin, ModalPlugin , VBToggle } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import i18n from '@/libs/i18n'
import router from './router'
import "./plugins"
import store from './store'
import "@/assets/projects.scss"
import Vuesax from 'vuesax'
import { StripePlugin } from '@vue-stripe/vue-stripe';
export const eventBus = new Vue();
import 'vue-datetime/dist/vue-datetime.css'

const options = {
  pk: "pk_test_51IifTsHEnh9El5KsRcLrbW0bYoiHkmGM87SZSredPsTEeoQ4tmlmQlzdorO588pwWU5yjKEsfULH470BATJ0Lsqh00YeHILRjy",
  stripeAccount: process.env.STRIPE_ACCOUNT,
  apiVersion: process.env.API_VERSION,
  locale: process.env.LOCALE,
};
Vue.use(StripePlugin, options);

import 'vuesax/dist/vuesax.css' //Vuesax styles
Vue.use(Vuesax, {
  // options here
})
export default {
  data: () => ({
    active: false,

  })
}


import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')
require('@/assets/css/styles.css')
import VueRecord from '@codekraft-studio/vue-record'

Vue.use(VueRecord)
Vue.directive('b-toggle', VBToggle)
Vue.config.productionTip = false

// import VueChannelColorPicker from "vue-channel-color-picker";
// Vue.use(VueChannelColorPicker);

new Vue({
  router,
  store,

  i18n,
  render: h => h(App),
}).$mount('#app')

