export default {
  calendarOptions: [
    {
      color: 'danger',
      label: 'Personal',
    },
    {
      color: 'primary',
      label: 'Business',
    },
    {
      color: 'warning',
      label: 'Family',
    },
    {
      color: 'success',
      label: 'Holiday',
    },
    {
      color: 'info',
      label: 'ETC',
    },
  ],
  selectedCalendars: ['Personal', 'Business', 'Family', 'Holiday', 'ETC'],
  events:[]
}
