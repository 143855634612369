const INITIAL_DATA_URL = "https://backendapinodejs.timecheckit.com/api/objectifs"
import axios from "axios"


export default {

  fetchObj({commit},payload){
    axios
    .get(INITIAL_DATA_URL,payload)
      .then((res) => {
        commit("SET_OBJ_COUNT",res.data)
        commit("SET_OBJ_DATA", res.data)
        commit("SET_DONE_OBJ",res.data.data.filter(obj => obj.isDone))
        commit("SET_UNDONE_OBJ",res.data.data.filter(obj => !obj.isDone))
        commit("SET_INPROGRESS_OBJ",res.data.data.filter(obj => obj.isProgress))
        
      })
    },
  addObj(ctx, obj) {
  
    obj.time = obj.time.name
    obj.timeline = obj.timeline.name
    
      axios
        .post(INITIAL_DATA_URL, obj )
        .then((res)=>{
          // this.fetchObj;
           this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Objectif ajouté ',
              icon: 'EditIcon',
              variant: 'success',
            }, 
          })
           console.log("ADDED");
         })
        .catch(error => console.log(error))
    },
  updateObj(ctx, obj) {
    console.log("obj to update", obj)
    obj.time = obj.time.name
    obj.timeline = obj.timeline.name
    console.log("obj to update", obj)
        axios
          .put('https://backendapinodejs.timecheckit.com/api/objectifs/'+ obj._id, obj )
          .then((res)=>{
            ctx.dispatch('fetchObj');
            window.location.reload()
            this.$toast({
             component: ToastificationContent,
             props: {
               title: 'Objectif modifié ',
               icon: 'EditIcon',
               variant: 'success',
             },
           })
            console.log("UPDATED");
          })
         .catch(error => console.log(error))
    },
    removeObj(ctx, objid) {

        axios
          .delete('https://backendapinodejs.timecheckit.com/api/objectifs/'+ objid)
          .then((res)=>{
            ctx.dispatch('fetchObj');
           window.location.reload()
           console.log("DELETED");
         })
        .catch(error => console.log('error delete',error))
       }



}
