import Vue from "vue"

// Lib to create guid
const s4 = () =>
  Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)
const guid = () => s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4()

export default {
  // Set Initial Data
  SET_DATA(state, payload) {
    state.meetings = payload
  },

  // Save Task Board
  SAVE_MEETING(state, payload) {
    const meet = state.meetings.find(b => b.id == payload.id)
    const itemIdx = state.meetings.findIndex(b => b.id == payload.id)

    // For existing item
    if (itemIdx > -1) {
      meet.name = payload.name
      meet.description = payload.description
      meet.duration = payload.duration
      Vue.set(state.meetings, itemIdx, meet)
    }
    // For new item
    else {
      const meet = {
        id: guid(),
        name: payload.name,
        duration:payload.duration,
        description: payload.description,
        completed: false,
      }
      state.meetings.push(meet)
    }
  },

  // Archive Task Board
  COMPLETE_MEET(state, payload) {
    const meet = state.meetings.find(b => b.id == payload.meetId)
    const meetIdx = state.meetings.findIndex(b => b.id == payload.meetId)
    meet.completed = true
    Vue.set(state.meetings, meetIdx, meet)
  },

  // Restore Task Board
  RESTORE_MEET(state, payload) {
    const meet = state.meetings.find(b => b.id == payload.meetId)
    const meetIdx = state.meetings.findIndex(b => b.id == payload.meetId)
    meet.completed = false
    Vue.set(state.meetings, meetIdx, meet)
  },
  SELECT_MEETING: (state, payload) => {
    state.selectedmeeting = payload
  },

  SET_ACTIVE_MEET(state, payload) {
    state.activemeetings = payload.meet
 },

  // // Save Task List Item
  // SAVE_TASKLIST_ITEM(state, payload) {
  //   const board = state.boards.find(b => b.id == payload.boardId)
  //   const list = board.lists.find(l => l.id == payload.listId)
  //   const itemIdx = list.items.findIndex(item => item.id == payload.item.id)

  //   // For existing item
  //   if (itemIdx > -1) {
  //     Vue.set(list.items, itemIdx, payload.item)
  //   }
  //   // For new item
  //   else {
  //     payload.item.id = guid()
  //     list.items.push(payload.item)
  //   }
  // },

  // // Delete Task List Item
  // DELETE_TASKLIST_ITEM(state, payload) {
  //   const board = state.boards.find(b => b.id == payload.boardId)
  //   const list = board.lists.find(l => l.id == payload.listId)
  //   const itemIdx = list.items.findIndex(item => item.id == payload.item.id)
  //   // For existing item
  //   if (itemIdx > -1) {
  //     Vue.delete(list.items, itemIdx)
  //   }
  // }
}
