export default {
  isLoading: true,
  activemeetings: null,
  meetings: [],
  selectedmeeting:{},
  undeletedMeetings: [
    {
     id: 132,
     user: "Ayoub",
     MeetingName: "helllo",
     MeetingDescription: "lorem uqdfq",
     statu: true,
     datedebut: null,
     datefin: null
   },
   {
     id: 32,
     user: "omar",
     MeetingName: "test test",
     MeetingDescription: "lorem upsudsfqm",
     statu: false,
     datedebut: null,
     datefin: null
   },
   {
     id: 52,
     user: "Malek",
     MeetingName: "good day",
     MeetingDescription: "lorem uqdsfqpsum",
     statu: true,
     datedebut: null,
     datefin: null
   }
 ],
}
