const INITIAL_DATA_URL = "https://backendapinodejs.timecheckit.com/api/users";
import axios from "axios";

var userAction = {
  async fetchUsers({ commit }) {
    commit("SET_LOADING_STATE", true)
    return axios.get(INITIAL_DATA_URL).then(res => {
      commit("SET_INITIAL_USERS", res.data);
      commit("SET_LOADING_STATE", false);
    })
  },  
}

export default userAction;
