import Vue from "vue"

// Lib to create guid
const s4 = () =>
  Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)
const guid = () => s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4()

var projectMutation = {
  // export default {

  // Set Initial Data

  SET_INITIAL_DATA(state, payload) {
    state.boards = payload.data
    localStorage.setItem('boards', JSON.stringify(payload.data));
  },

  SET_PROJECT(state, payload) {
    state.board = payload.data;
  },

  SET_PROJECT_COUNT(state, payload) {
    state.projectCount = payload.count
    localStorage.setItem('projectcount', payload.count);

  },

  // Set Loading State
  SET_LOADING_STATE(state, payload) {
    state.isLoading = payload
  },

  //Get item by ID

  // Save Task Board
  SAVE_TASKBOARD(state, payload) {
    const board = {
      name: payload.name,
      description: payload.description,
      archived: false,
      lists: []
    };
    state.boards.push(board);
  },

  // Archive Task Board
  ARCHIVE_TASKBOARD(state, payload) {
    const board = state.boards.find(b => b.id == payload.boardId)
    const boardIdx = state.boards.findIndex(b => b.id == payload.boardId)
    board.status = "inactive"
    Vue.set(state.boards, boardIdx, board)
  },

  // Restore Task Board
  RESTORE_TASKBOARD(state, payload) {
    const board = state.boards.find(b => b._id == payload.boardId)
    const boardIdx = state.boards.findIndex(b => b.id == payload.boardId)
    board.status = "active"
    Vue.set(state.boards, boardIdx, board)
  },

  // Save Task List
  SAVE_TASKLIST(state, payload) {
    const list = {
      project: payload.boardId,
      name: payload.name,
      headerColor: "#9600ff",
      listItems: []
    };
    console.log("list", state, payload, list)
    if (state.boards) {
      state.boards.filter(b => b._id == payload.boardId)[0].lists =
        state.boards.filter(b => b._id == payload.boardId)[0].lists != undefined &&
          state.boards.filter(b => b._id == payload.boardId)[0].lists.length >= 0 ?
          [...state.boards.filter(b => b._id == payload.boardId)[0].lists, list] :
          [];
    }

  },
  //update Task List
  UPDATE_TASKLIST(state, payload) {
    projectMutation.SET_INITIAL_DATA(state, payload)
  },
  // Archive Task List
  ARCHIVE_TASKLIST(state, payload) {
    const board = state.activeBoard
    const list = board.lists.find(l => l._id == payload.listId)
    const listIdx = board.lists.findIndex(l => l._id == payload.listId)
    list.status = "inactive"
    Vue.set(board.lists, listIdx, list)
  },

  // Restore Task List
  RESTORE_TASKLIST(state, payload) {
    const board = state.boards.find(b => b._id == payload.boardId)
    const list = board.lists.find(l => l._id == payload.listId)
    const listIdx = board.lists.findIndex(l => l._id == payload.listId)
    list.archived = false
    Vue.set(board.lists, listIdx, list)
  },

  // Reorder TaskBoad Lists
  REORDER_TASKLISTS(state, payload) {
    const board = state.boards.find(b => b._id == payload.boardId)
    Vue.set(board, "lists", payload.lists)
  },

  // Reorder Task List Items
  REORDER_TASKLIST_ITEMS(state, payload) {
    const board = state.boards.find(b => b._id == payload.boardId)
    const listIdx = board.lists.findIndex(l => l._id == payload.listId)
    Vue.set(board.lists[listIdx], "items", payload._items)
  },

  // Set Active Board
  SET_ACTIVE_TASKBOARD(state, payload) {
    state.activeBoard = payload.board
  },
  // Set InActive Board
  SET_iN_ACTIVE_TASKBOARD(state, payload) {
    state.inActiveBoard = payload.board
  },

  // Set Active list
  SET_ACTIVE_TASKLIST(state, payload) {
    state.unarchivedLists = payload.board
  },
  // Set InActive list
  SET_IN_ACTIVE_TASKLIST(state, payload) {
    state.archivedLists = payload.board
  },

  // Save Task List Item
  SAVE_TASKLIST_ITEM(state, payload) {
    state.boards.filter(b => b._id == payload.boardId)[0].lists.filter(list => list._id == payload.listId)[0].items =
      state.boards.filter(b => b._id == payload.boardId)[0].lists.filter(list => list._id == payload.listId)[0].items != undefined &&
        state.boards.filter(b => b._id == payload.boardId)[0].lists.filter(list => list._id == payload.listId)[0].items.length >= 0 ?
        [...state.boards.filter(b => b._id == payload.boardId)[0].lists.filter(list => list._id == payload.listId)[0].items, payload.item] :
        [];
  },

  // Delete Task List Item
  DELETE_TASKLIST_ITEM(state, payload) {
    const board = state.boards.find(b => b._id == payload.boardId)
    const list = board.lists.find(l => l._id == payload.listId)
    const itemIdx = list.items.findIndex(item => item._id == payload.item._id)
    // For existing item
    if (itemIdx > -1) {
      Vue.delete(list.items, itemIdx)
    }
  }
}
export default projectMutation;