import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@axios'

// Modules
import app from './app'
import meetstore from './MeetingStore'

import pstore from './ProjectStore'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import Objectives from './Objectives'
import todoModule from './todoModule'
import calendarModule from "./calendarModule"
import userModule from './UserModule';

Vue.use(Vuex)

export default new Vuex.Store({

  modules: {
    app,
    pstore,
    meetstore,
    appConfig,
    verticalMenu,
    Objectives,
    todoModule,
    calendarModule,
    userModule
  },
  /* state:{
  todos:localStorage.getItem('todos')
  },
  actions:{
  
  }*/
})
