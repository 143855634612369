import axios from "axios"
import store from "..";


export default {
  fetchEvents(ctx, calendartype) {
    console.log("here")
        /*axios
        .get('https://backendapinodejs.timecheckit.com/api/calender/',calendartype
        ).then((res)=>{

         res.data.data.forEach(element => {
           element.startdate=new Date(element.startdate)
           element.enddate=new Date(element.enddate)
       store.state.calendarModule.events=res.data.data.filter(event => event.tags.includes(calendartype[0]))
             console.log("aaaaaaaaaaaaaaaaaaa",calendartype);
         }); 

        console.log("FETCH WORKING CALENDAR!",store.state.calendarModule.events);
        localStorage.setItem("events",JSON.stringify(store.state.calendarModule.events))  
      })*/
  },
 
  addEvent(ctx, event) {
    axios
      .post('https://backendapinodejs.timecheckit.com/api/calender/add-event/', event )
      .then((res)=>{
         ctx.dispatch('fetchEvents');
         window.location.reload()
         console.log("ADDED");
       })
      .catch(error => console.log(error))
  },
  updateEvent(ctx, { event }) {
    axios
    .put('https://backendapinodejs.timecheckit.com/api/calender/modify-event/'+ event._id,event)
    .then((res)=>{
      ctx.dispatch('fetchEvents');
      window.location.reload()
      console.log("UPDATED");
    })
   .catch(error => console.log(error))
  },


  removeEvent(ctx, { id }) {
      axios
        .delete('https://backendapinodejs.timecheckit.com/api/calender/'+id)
        .then((res) => {
          ctx.dispatch('fetchEvents');
          window.location.reload()
        })
        .catch(error => reject(error))
    
  },
}
