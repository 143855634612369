export default {
  isLoading: false,
  activeBoard: null,
  inActiveBoard: null,
  boards: JSON.parse(localStorage.getItem('boards')),
  archivedLists:null,
  unarchivedLists:null,
  board: {},
  projectCount:JSON.parse(localStorage.getItem('projectcount'))
}
