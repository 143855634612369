import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  faqData: {
    // payment
    payment: {
      icon: 'ShoppingBagIcon',
      title: 'Subscription Plans and Pricing',
      subtitle: '',
      qandA: [
        {
          question: 'What are the available subscription plans on TimeCheckIt, and how much do they cost?',
          ans:
            `TimeCheckIt offers four subscription plans:<br>
            - <b>Basic:</b>: $3.99 billed every month<br>
            - <b>Professional:</b>: $1.99 per month billed annually (\$23.88 annually)<br>
            - <b>Standard:</b>: $2.99 per month billed every 6 months (\$17.94 annually)<br>
            - <b>Teams:</b>: $3.99 per month billed annually (minimum 5 users, \$23.88 annually per user`,
        },
        {
          question: 'Is there a free trial, and do I need to provide payment information to access it?',
          ans:
            'Yes, TimeCheckIt provides a 15-day free trial. Payment information is not required during the trial period',
        }
      ],
    },
    // delivery
    delivery: {
      icon: 'CreditCardIcon',
      title: 'Payment and Billing',
      subtitle: '',
      qandA: [
        {
          question: "How does billing work on TimeCheckIt?",
          ans: "Billing on TimeCheckIt is manual. Users will manually make payments based on the chosen plan and billing frequency."
        },
        {
          question: "Can I cancel my subscription, and is there a refund policy?",
          ans: "Subscription cancellations are not available. There is typically no refund policy, so users are encouraged to use the free trial to assess the platform."
        },
        {
          question: "Can I update my payment information on TimeCheckIt?",
          ans: "Yes, users can update their payment information by accessing the billing or account settings."
        },
        {
          question: "What payment methods are accepted on TimeCheckIt?",
          ans: "TimeCheckIt accepts payments through major credit/debit cards. Users can check the platform for specific supported payment methods."
        },
        {
          question: "Is there an automatic renewal for subscriptions on TimeCheckIt?",
          ans: "No, TimeCheckIt does not have automatic renewal. Users will need to manually renew their subscription at the end of the billing period."
        },
        {
          question: "Are there any hidden fees associated with TimeCheckIt subscriptions?",
          ans: "TimeCheckIt is transparent about its pricing, and there are no hidden fees. Users are billed according to their selected plan and billing frequency."
        },
        {
          question: "How do I start the 15-day free trial without providing payment information?",
          ans: "Users can sign up for the 15-day free trial on TimeCheckIt without providing payment information. Simply create an account and explore the platform."
        }
      ],
    },
    // cancellation and return
    cancellationReturn: {
      icon: 'CalendarIcon',
      title: 'Calendar',
      subtitle: 'TimeCheckIt Features',
      qandA: [
        {
          "question": "How does the Calendar feature enhance scheduling and task tracking on TimeCheckIt?",
          "ans": "Thanks to our flexible schedule, you can view and track all your old and new tasks with a smart to-do list and in multiple calendar views."
        },
        {
          "question": "What are the available views in the Calendar feature on TimeCheckIt?",
          "ans": "The Calendar feature on TimeCheckIt provides multiple views, allowing users to smartly organize and track tasks in various formats."
        }
      ],
    },
    // my orders
    myOrders: {
      icon: 'CheckSquareIcon',
      title: 'To-Do List',
      subtitle: 'TimeCheckIt Features',
      qandA: [
        {
          "question": "How does the To-Do List feature on TimeCheckIt help in task organization?",
          "ans": "Arrange your tasks and never miss a deadline with an all-in-one task-checker."
        },
        {
          "question": "Are there any additional features in the To-Do List for task management on TimeCheckIt?",
          "ans": "The To-Do List module allows users to add tasks, set reminders, attach voice messages, and efficiently manage their assignments."
        }
      ],
    },
    // product and services
    productServices: {
      icon: 'CoffeeIcon',
      title: 'Meeting',
      subtitle: 'TimeCheckIt Features',
      qandA: [
        {
          "question": "How does the Meetings feature on TimeCheckIt contribute to advanced meeting management?",
          "ans": "Interact with your work environment and avoid any time conflicts in an advanced meeting management space."
        },
        {
          "question": "Are there specific functionalities within the Meetings feature on TimeCheckIt?",
          "ans": "The Meetings feature includes the ability to schedule, manage, and interact seamlessly within a collaborative meeting environment."
        }
      ],
    },
    goal: {
      icon: 'CheckCircleIcon',
      title: 'Goals',
      subtitle: 'TimeCheckIt Features',
      qandA: [
        {
          "question": "How can users utilize the Goals feature on TimeCheckIt to set and track objectives?",
          "ans": "Set missions, pre-set reminders, and follow your progress with respect to your engagements."
        },
        {
          "question": "Are there customization options available within the Goals feature on TimeCheckIt?",
          "ans": "Users can personalize their goals, set reminders, and evaluate their progress based on individual preferences."
        },
      ],
    },
    chat: {
      icon: 'MessageSquareIcon',
      title: 'Chat',
      subtitle: 'TimeCheckIt Features',
      qandA: [
        {
          "question": "How does the Chat feature on TimeCheckIt enhance communication within the platform?",
          "ans": "Communicate with colleagues and relatives through an in-the-app chat space."
        },
        {
          "question": "Are there any additional functionalities within the Chat feature for effective communication?",
          "ans": "The Chat feature provides an efficient in-app communication space for seamless collaboration."
        },
      ],
    },
    proj: {
      icon: 'PackageIcon',
      title: 'Project',
      subtitle: 'TimeCheckIt Features',
      qandA: [
        {
          "question": "How does the Projects feature on TimeCheckIt simplify group work management?",
          "ans": "Manage all your group works in one place and allocate and evaluate tasks in real time in our project management interface."
        },
        {
          "question": "What functionalities are available for task allocation and evaluation within the Projects feature?",
          "ans": "The Projects feature allows users to allocate tasks, manage group works, and evaluate progress within a collaborative project management interface."
        },
      ],
    },
    analytics: {
      icon: 'DatabaseIcon',
      title: 'Analytics',
      subtitle: 'TimeCheckIt Features',
      qandA: [
        {
          "question": " How does the Analytics feature on TimeCheckIt track the advancement of tasks, goals, and projects?",
          "ans": "Track the advancement of your tasks, goals, and projects to better adjust your objectives."
        },
        {
          "question": "What insights can users gain from the Analytics feature on TimeCheckIt?",
          "ans": "The Analytics feature provides valuable insights into task, goal, and project progress for informed decision-making."
        },
      ],
    },
    groups: {
      icon: 'UsersIcon',
      title: 'Groups',
      subtitle: 'TimeCheckIt Features',
      qandA: [
        {
          "question": "How can users create and manage groups on TimeCheckIt?",
          "ans": "Create your group and manage your team using our platform."
        },
        {
          "question": "Are there specific functionalities within the Groups feature for efficient team management?",
          "ans": "The Groups feature provides tools for creating and managing teams, facilitating efficient collaboration."
        }
      ],
    },
  },
}

mock.onGet('/faq/data').reply(config => {
  const { q = '' } = config.params
  const queryLowered = q.toLowerCase()

  const filteredData = {}

  Object.entries(data.faqData).forEach(entry => {
    const [categoryName, categoryObj] = entry
    // eslint-disable-next-line arrow-body-style
    const filteredQAndAOfCategory = categoryObj.qandA.filter(qAndAObj => {
      return qAndAObj.question.toLowerCase().includes(queryLowered)
    })
    if (filteredQAndAOfCategory.length) {
      filteredData[categoryName] = { ...categoryObj, qandA: filteredQAndAOfCategory }
    }
  })

  return [200, filteredData]
})
