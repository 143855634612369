import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
    "faqData": {
      "payment": {
        "icon": "ShoppingBagIcon",
        "title": "Plans d'abonnement et tarification",
        "subtitle": "",
        "qandA": [
          {
            "question": "Quels sont les plans d'abonnement disponibles sur TimeCheckIt et combien coûtent-ils?",
            "ans": "TimeCheckIt propose quatre plans d'abonnement :<br>- <b>Basic</b> : 3,99 $ facturés chaque mois<br>- <b>Professional</b> : 1,99 $ par mois facturé annuellement (23,88 $ par an)<br>- <b>Standard</b> : 2,99 $ par mois facturé tous les 6 mois (17,94 $ par an)<br>- <b>Teams</b> : 3,99 $ par mois facturé annuellement (minimum 5 utilisateurs, 23,88 $ par an par utilisateur)"
          },
          {
            "question": "Y a-t-il un essai gratuit, et dois-je fournir des informations de paiement pour y accéder?",
            "ans": "Oui, TimeCheckIt propose un essai gratuit de 15 jours. Aucune information de paiement n'est requise pendant la période d'essai."
          }
        ]
      },
      "delivery": {
        "icon": "CreditCardIcon",
        "title": "Paiement et facturation",
        "subtitle": "",
        "qandA": [
          {
            "question": "Comment fonctionne la facturation sur TimeCheckIt?",
            "ans": "La facturation sur TimeCheckIt est manuelle. Les utilisateurs effectueront manuellement les paiements en fonction du plan choisi et de la fréquence de facturation."
          },
          {
            "question": "Puis-je annuler mon abonnement, et y a-t-il une politique de remboursement?",
            "ans": "Les annulations d'abonnement ne sont pas disponibles. En général, il n'y a pas de politique de remboursement, donc les utilisateurs sont encouragés à utiliser l'essai gratuit pour évaluer la plateforme."
          },
          {
            "question": "Puis-je mettre à jour mes informations de paiement sur TimeCheckIt?",
            "ans": "Oui, les utilisateurs peuvent mettre à jour leurs informations de paiement en accédant aux paramètres de facturation ou de compte."
          },
          {
            "question": "Quels modes de paiement sont acceptés sur TimeCheckIt?",
            "ans": "TimeCheckIt accepte les paiements par carte de crédit/débit. Les utilisateurs peuvent vérifier sur la plateforme les modes de paiement spécifiques pris en charge."
          },
          {
            "question": "Y a-t-il un renouvellement automatique pour les abonnements sur TimeCheckIt?",
            "ans": "Non, TimeCheckIt n'a pas de renouvellement automatique. Les utilisateurs devront renouveler manuellement leur abonnement à la fin de la période de facturation."
          },
          {
            "question": "Y a-t-il des frais cachés associés aux abonnements TimeCheckIt?",
            "ans": "TimeCheckIt est transparent concernant sa tarification, et il n'y a pas de frais cachés. Les utilisateurs sont facturés selon leur plan et leur fréquence de facturation sélectionnés."
          },
          {
            "question": "Comment puis-je commencer l'essai gratuit de 15 jours sans fournir d'informations de paiement?",
            "ans": "Les utilisateurs peuvent s'inscrire à l'essai gratuit de 15 jours sur TimeCheckIt sans fournir d'informations de paiement. Il suffit de créer un compte et d'explorer la plateforme."
          }
        ]
      },
      "cancellationReturn": {
        "icon": "CalendarIcon",
        "title": "Calendrier",
        "subtitle": "Fonctionnalités de TimeCheckIt",
        "qandA": [
          {
            "question": "Comment la fonctionnalité de calendrier améliore-t-elle la planification et le suivi des tâches sur TimeCheckIt?",
            "ans": "Grâce à notre emploi du temps flexible, vous pouvez consulter et suivre toutes vos anciennes et nouvelles tâches avec une liste intelligente de tâches et dans plusieurs vues de calendrier."
          },
          {
            "question": "Quelles sont les vues disponibles dans la fonctionnalité de calendrier sur TimeCheckIt?",
            "ans": "La fonctionnalité de calendrier sur TimeCheckIt offre plusieurs vues, permettant aux utilisateurs d'organiser et de suivre intelligemment les tâches dans divers formats."
          }
        ]
      },
      "myOrders": {
        "icon": "CheckSquareIcon",
        "title": "Liste de tâches",
        "subtitle": "Fonctionnalités de TimeCheckIt",
        "qandA": [
          {
            "question": "Comment la fonctionnalité de liste de tâches sur TimeCheckIt aide-t-elle à l'organisation des tâches?",
            "ans": "Organisez vos tâches et ne manquez jamais une échéance avec un vérificateur de tâches tout-en-un."
          },
          {
            "question": "Y a-t-il des fonctionnalités supplémentaires dans la liste de tâches pour la gestion des tâches sur TimeCheckIt?",
            "ans": "Le module de liste de tâches permet aux utilisateurs d'ajouter des tâches, de définir des rappels, d'attacher des messages vocaux et de gérer efficacement leurs affectations."
          }
        ]
      },
      "productServices": {
        "icon": "CoffeeIcon",
        "title": "Réunions",
        "subtitle": "Fonctionnalités de TimeCheckIt",
        "qandA": [
          {
            "question": "Comment la fonctionnalité de réunions sur TimeCheckIt contribue-t-elle à une gestion avancée des réunions?",
            "ans": "Interagissez avec votre environnement de travail et évitez tout conflit horaire dans un espace de gestion de réunions avancé."
          },
          {
            "question": "Y a-t-il des fonctionnalités spécifiques dans la fonctionnalité de réunions sur TimeCheckIt?",
            "ans": "La fonctionnalité de réunions inclut la possibilité de planifier, gérer et interagir de manière transparente dans un environnement de réunion collaboratif."
          }
        ]
      },
      "goal": {
        "icon": "CheckCircleIcon",
        "title": "Objectifs",
        "subtitle": "Fonctionnalités de TimeCheckIt",
        "qandA": [
          {
            "question": "Comment les utilisateurs peuvent-ils utiliser la fonctionnalité des objectifs sur TimeCheckIt pour définir et suivre des objectifs?",
            "ans": "Définissez des missions, des rappels prédéfinis et suivez votre progression par rapport à vos engagements."
          },
          {
            "question": "Des options de personnalisation sont-elles disponibles dans la fonctionnalité des objectifs sur TimeCheckIt?",
            "ans": "Les utilisateurs peuvent personnaliser leurs objectifs, définir des rappels et évaluer leur progression en fonction de leurs préférences individuelles."
          }
        ]
      },
      "chat": {
        "icon": "MessageSquareIcon",
        "title": "Chat",
        "subtitle": "Fonctionnalités de TimeCheckIt",
        "qandA": [
          {
            "question": "Comment la fonctionnalité de chat sur TimeCheckIt améliore-t-elle la communication au sein de la plateforme?",
            "ans": "Communiquez avec des collègues et des proches à travers un espace de chat intégré à l'application."
          },
          {
            "question": "Y a-t-il des fonctionnalités supplémentaires dans la fonctionnalité de chat pour une communication efficace?",
            "ans": "La fonctionnalité de chat offre un espace de communication in-app efficace pour une collaboration transparente."
          }
        ]
      },
      "proj": {
        "icon": "PackageIcon",
        "title": "Projet",
        "subtitle": "Fonctionnalités de TimeCheckIt",
        "qandA": [
          {
            "question": "Comment la fonctionnalité des projets sur TimeCheckIt simplifie-t-elle la gestion du travail de groupe?",
            "ans": "Gérez tous vos travaux de groupe en un seul endroit et attribuez et évaluez les tâches en temps réel dans notre interface de gestion de projet collaborative."
          },
          {
            "question": "Quelles fonctionnalités sont disponibles pour l'allocation et l'évaluation des tâches dans la fonctionnalité des projets?",
            "ans": "La fonctionnalité des projets permet aux utilisateurs d'attribuer des tâches, de gérer des travaux de groupe et d'évaluer les progrès au sein d'une interface de gestion de projet collaborative."
          }
        ]
      },
      "analytics": {
        "icon": "DatabaseIcon",
        "title": "Analytique",
        "subtitle": "Fonctionnalités de TimeCheckIt",
        "qandA": [
          {
            "question": "Comment la fonctionnalité d'analytique sur TimeCheckIt suit-elle l'avancement des tâches, des objectifs et des projets?",
            "ans": "Suivez l'avancement de vos tâches, objectifs et projets pour mieux ajuster vos objectifs."
          },
          {
            "question": "Quelles informations les utilisateurs peuvent-ils obtenir de la fonctionnalité d'analytique sur TimeCheckIt?",
            "ans": "La fonctionnalité d'analytique fournit des informations précieuses sur l'avancement des tâches, des objectifs et des projets pour une prise de décision éclairée."
          }
        ]
      },
      "groups": {
        "icon": "UsersIcon",
        "title": "Groupes",
        "subtitle": "Fonctionnalités de TimeCheckIt",
        "qandA": [
          {
            "question": "Comment les utilisateurs peuvent-ils créer et gérer des groupes sur TimeCheckIt?",
            "ans": "Créez votre groupe et gérez votre équipe en utilisant notre plateforme."
          },
          {
            "question": "Y a-t-il des fonctionnalités spécifiques dans la fonctionnalité des groupes pour une gestion efficace des équipes?",
            "ans": "La fonctionnalité des groupes fournit des outils pour créer et gérer des équipes, facilitant une collaboration efficace."
          }
        ]
      }
    }
  
}

mock.onGet('/faqFr/data').reply(config => {
  const { q = '' } = config.params
  const queryLowered = q.toLowerCase()

  const filteredData = {}

  Object.entries(data.faqData).forEach(entry => {
    const [categoryName, categoryObj] = entry
    // eslint-disable-next-line arrow-body-style
    const filteredQAndAOfCategory = categoryObj.qandA.filter(qAndAObj => {
      return qAndAObj.question.toLowerCase().includes(queryLowered)
    })
    if (filteredQAndAOfCategory.length) {
      filteredData[categoryName] = { ...categoryObj, qandA: filteredQAndAOfCategory }
    }
  })

  return [200, filteredData]
})
