export default {
  selectedmeeting: state=>id =>{
    return state.meetings.find(meetid => meetid.id === id)
  },
  allMeetings: state => state.meetings,
  activeMeetings: state => state.activemeetings,
  undeletedMeetings: state => state.meetings.filter(b => !b.completed),
  deletedMeetings: state => state.meetings.filter(b => b.completed),

}
