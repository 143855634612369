import Vue from "vue"

export default {
  SET_OBJ_COUNT(state,payload){
    state.objectivescount=payload.count
    localStorage.setItem('objcount',JSON.stringify(payload.count))
  },
  SET_OBJ_DATA(state,payload){
    state.objectives=payload.data
    localStorage.setItem('objectives',JSON.stringify(payload.data))
  },
  SET_DONE_OBJ(state,payload){
    state.doneobj=payload
    localStorage.setItem('doneobj',JSON.stringify(payload))
  },
  SET_UNDONE_OBJ(state,payload){
    state.undoneobj=payload
    localStorage.setItem('undoneobj',JSON.stringify(payload))
  },
  SET_INPROGRESS_OBJ(state,payload){
    state.inprogress=payload
    localStorage.setItem('inprogress',JSON.stringify(payload))
  }
}
